<template>
  <div>
    <gmap-map ref="mapRef" :options="mapOptions" v-bind="gmapParams">
      <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">{{infoContent}}</gmap-info-window>
      <gmap-marker @dragend="updatePosition" :position="position" :draggable="!readonly" :icon="positionIconValue" :title="titleValue"></gmap-marker>
      <gmap-marker v-for="(m, i) in markers" :position="m.position" :clickable="!readonly" :draggable="!readonly" @click="toggleInfoWindow(m,i)" :key="m.id" :icon="positionIconValue"></gmap-marker>
    </gmap-map>
  </div>
</template>
<script>
import Vue from 'vue'
import GmapVue from 'gmap-vue'
import mapIcon from '@images/cleaner-map-icon.png'
import { getGoogleMapsAPI } from 'gmap-vue'
import api from '@/admins/api'

Vue.use(GmapVue, {
  load: {
    key: Window.gmap_key,
    libraries: 'places',
    //// Demonstrating how we can customize the name of the components
    installComponents: false,
    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    autobindAllEvents: false,
    // Load the Google Maps API dynamically, if you set this to `true` the plugin doesn't load the Google Maps API
    //dynamicLoad: true,
    dynamicLoad: false
  }
})

export default {
  model: {
    prop: 'position',
    event: 'change'
  },
  //// markers: [ { name: 'One', position: { lat: 33.9344253297911, lng: -118.145802857031 } } ]
  props: {
    address: {
      type: String
    },
    center: {
      type: Object,
    },
    detectLocation: {
      type: Boolean,
    },
    hideUi: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    positionIcon: {
      type: String,
    },
    markers: {
      type: Array,
    },
    position: {
      type: Object,
    },
    title: {
      type: String,
    },
    zoom: {
      type: Number,
    },
    mapClass: {
      type: String,
      default: 'map'
    },
  },
  data() {
    return {
      map: null,
      infoContent: '',
      infoWindowPos: null,
      infoWinOpen: false,
      currentLocation: { lat: 0, lng: 0 },
      currentMidx: null,
      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      detectionDisabled: false
    }
  },
  computed: {
    centerValue() {
      if (this.center) {
        return this.center
      }
      if (this.hasPosition) {
        return this.position
      }
      return this.currentLocation
    },
    gmapParams() {
      let params = { zoom: this.zoomValue, class: this.mapClass }
      if (this.centerValue) {
        params.center = this.centerValue
      }
      return params
    },
    google: getGoogleMapsAPI,
    hasPosition() {
      if (this.position == undefined)
        return false
      return (this.position.lat != 0) && (this.position.lng != 0)
    },
    mapOptions() {
      if (this.hideUi) {
        return {
          draggable: false,
          fullscreenControl: false,
          gestureHandling: 'cooperative',
          mapTypeControl: false,
          navigationControl: false,
          rotateControl: false,
          scaleControl: false,
          scrollwheel: false,
          streetViewControl: false,
          zoomControl: false,
        }
      }
    },
    messageToShow() {
      if (this.message)
        return this.message
      return 'Processing'
    },
    positionValue() {
      return this.position ? this.position : this.currentLocation
    },
    positionIconValue() {
      return this.positionIcon ? this.positionIcon : mapIcon
    },
    titleValue() {
      return this.title ? this.title : 'Address'
    },
    zoomValue() {
      return this.zoom ? this.zoom : 12
    },
    classValue() {
      return this.customClass ? this.customClass : 'map'
    }
  },
  created() {
    this.$parent.$on('geocodeAddress', this.searchLocation)
  },
  mounted() {
    let do_not_detect = false
    if (this.$refs.mapRef) {
      console.log("location_map mounted")
      this.$refs.mapRef.$mapPromise.then((map) => {
        console.log("location_map mounted mapPromise")
        this.map = map
        this.$root.$emit('google-map-loaded')
        if (!this.hasPosition && this.address) {
          this.searchLocation();
          do_not_detect = true
        }
      })

      if (do_not_detect) return
      if (this.detectLocation || !this.hasPosition)
        this.geolocation();
    } else {
      console.error('mapRef is missing')
    }
  },
  methods: {
    updatePosition(e) {
      this.position.lat = e.latLng.lat()
      this.position.lng = e.latLng.lng()
    },
    geolocation() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.currentLocation.lat = position.coords.latitude,
        this.currentLocation.lng = position.coords.longitude
      }, (error) => {
        this.detectionDisabled = true
      })
    },
    searchLocation() {
      var geocoder = new google.maps.Geocoder()
      geocoder.geocode({'address': this.address}, (results, status) => {
        if (status === 'OK') {
          this.currentLocation.lat = results[0].geometry.location.lat()
          this.currentLocation.lng = results[0].geometry.location.lng()
        }
      })
    },
    toggleInfoWindow(cleaner, idx) {
      if (cleaner.name === undefined)
        return;
      this.infoWindowPos = cleaner.position;
        this.infoContent = cleaner.name;
      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    }
  },
  watch: {
    currentLocation(newValue, oldValue) {
      if (this.position !== undefined) {
        this.position.lat = newValue.lat
        this.position.lng = newValue.lng
      }
    }
  }
}
</script>
